<template>
    <div>
        <div class="card">
            <div class="bg-blue-light px-1 rounded pb-2">
                <TitleButton
                        class="mt-1"
                        btnTitle="Go Back"
                        title="Create Contra"
                        @onClickCloseButton="goToList"
                />
                <div class="row mt-1">
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Date</label>
                        <input
                            class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                            tabindex="1"
                            type="date"
                            placeholder=""
                            v-model="contra.date"
                        >
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Voucher</label>
                        <div
                            class="input-group input-group-merge invoice-edit-input-group has-validation"
                            :class="isEmptyVoucher ? 'is-invalid' : ''"
                        >
                            <div class="input-group-text">
                                <span :class="isEmptyVoucher ? 'text-danger' : ''">{{ contra.voucher_prefix }}-</span>
                            </div>
                            <input 
                                type="text" 
                                class="form-control invoice-edit-input"
                                aria-describedby="validationVoucherNameFeedback"
                                placeholder="" 
                                v-model="contra.voucher_serial"
                                :class="isEmptyVoucher ? 'is-invalid' : ''"
                            >
                        </div>
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-xl-3 col-form-label">Amount</label>
                        <input
                                v-model="amount"
                                @input="onInputAmount"
                                type="number"
                                class="form-control text-end"
                                placeholder="Amount"
                        >
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-xl-3 col-form-label">Party: </label>
                        <v-select
                                placeholder="Select Party"
                                class="w-100"
                                :options="contactProfiles"
                                label="name"
                                :reduce="name => name.id"
                                v-model="contra.contact_profile_id"
                        />
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Business</label>
                        <v-select
                                placeholder="Select Business"
                                class="w-100"
                                :options="businesses"
                                label="name"
                                :reduce="name => name.id"
                                v-model="contra.business_id"
                        />
                    </div>
                </div>
            </div>
            <div class="card p-2">
                <AddFormElement
                        class="mb-2"
                        :accountHeads="accountHeads"
                        v-for="(item, index) in contra.ledgers"
                        :key="index"
                        :index="index"
                        :item="item"
                />

                <div class="row mt-3">
                    <div class="col-12">
                        <div class="mb-2">
                            <label for="note" class="form-label fw-bold">Memo</label>
                            <textarea v-model="contra.note" placeholder="Memo" class="form-control" rows="2" id="note"></textarea>
                        </div>
                    </div>
                </div>

                <div class="mt-2 text-center">
                    <hr class="mb-1">
                    <button
                            :disabled="loading || isEmptyVoucher"
                            @click="saveContra(true)"
                            class="btn btn-primary me-1"
                    >
                        <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Save
                    </button>
                    <button
                            :disabled="saveNewLoader || isEmptyVoucher"
                            @click="saveContra(false)"
                            class="btn btn-primary me-1"
                    >
                        <div v-if="saveNewLoader" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Save & New
                    </button>
                    <button
                            @click="goToList"
                            class="btn btn-outline-secondary cancel-btn"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
        <VoucherValidationModal
            ref="voucherValidationModal"
            :voucherQuery="voucherQuery"
            @on-press-ok="setVoucherNumberAndDate(true)"
        />
    </div>
</template>

<script>
import handleContra from '@/services/modules/contra'
import handleContact from '@/services/modules/contact'
import handleCBusinesses from '@/services/modules/businesses'
import { inject } from 'vue'
import {generateVoucherNumber, generateQuery} from "@/services/utils/voucherNumberGenerator";
import TitleButton from '@/components/atom/TitleButton'
import AddFormElement from '@/components/molecule/company/contra/AddFormElement'
import AddButton from '@/components/atom/AddButton'
import VoucherValidationModal from "@/components/molecule/company/voucher/VoucherValidationModal.vue";
import figureFormatter from "@/services/utils/figureFormatter";

export default {
    name: 'ContraCreate',
    components: {
        VoucherValidationModal,
        TitleButton,
        AddFormElement,
        AddButton
    },
    data: () => ({
        amount: '',
        accountHeads: [],
        contactProfiles : [],
        businesses : [],
        saveNewLoader: false,
        isEmptyVoucher: false,
        contra: {
            company_id:'',
            contact_profile_id: null,
            business_id: null,
            date: '',
            note: '',
            mop_references: 'mop',
            voucher_no : '',
            voucher_prefix : '',
            voucher_serial : '',
            voucher_type: 'contra_voucher',
            ledgers: [
                {
                    account_head_id: null,
                    debit: null,
                    credit: null,
                    note: null,
                    tax_rate: null,
                    taxable_amount: null,

                },
                {
                    account_head_id: null,
                    debit: null,
                    credit: null,
                    note: null,
                    tax_rate: null,
                    taxable_amount: null,
                }
            ]
        }
    }),
    computed: {
        isLoading () {
            return this.loading || this.saveNewLoader
        },
        start () {
            return this.$route.query.start
        },
        end () {
            return this.$route.query.end
        },
        general () {
            return this.contra.ledgers
        },
        totalDebit () {
            let total = 0;
            this.general.map(i => {
                if(i.debit) total += i.debit
            })
            return total
        },
        voucherQuery () {
            return generateQuery(this.$route.params.companyId, 'contra_voucher', 'general_contra')
        }
    },
    
    watch: {
        'contra.voucher_prefix':function () {
            this.concatVoucher()
        },
        
        'contra.voucher_serial':function () {
            this.concatVoucher()
        }
    },
    
    methods: {
        concatVoucher(){
            this.isEmptyVoucher = false;
            let voucherSerial = this.decimalFormat(this.contra.voucher_serial.toString().replace(/[^0-9\.]+/g, ''))
            if(voucherSerial === '') {
                this.isEmptyVoucher = true;
            }
            this.contra.voucher_serial = voucherSerial;
            this.contra.voucher_no = `${this.contra.voucher_prefix}-${voucherSerial}`;
        },
        
        onInputAmount () {
            this.contra.ledgers[0].credit = this.amount
            this.contra.ledgers[1].debit = this.amount
        },
        async getAccountHeads() {
           // return;
            try {
                this.loading = true
                let res = await this.fetchAccountHeads(this.$route.params.companyId)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.accountHeads = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.data?.message)
                }
            } finally {
                this.loading = false
            }
        },
        async getContactProfiles() {
        try {
          let companyId = this.$route.params.companyId
          let contactId = '';
          let q = '';
          let query = '?company_id=' + companyId + '&q=' + q + '&contact_id' + contactId;
          let res = await this.fetchContactProfiles(query)

          if(!res.status) {
          }
          if(res.status) {
            this.contactProfiles = res.data;
          }

        } catch (err) {
          if(!err.response) {
            this.showError('Something is wrong. Check your connectivity!!')
          }
          if(err.response) {
            this.showError(err.response.message)
          }
        } finally {
          this.loading = false
        }
      },
        async getBusinesses(){
        try {
          let companyId = this.$route.params.companyId
          let res = await this.fetchBusinessList('?company_id=' + companyId)

          if(!res.status) {
          }
          if(res.status) {
            this.businesses = res.data;
          }

        } catch (err) {
          if(!err.response) {
            this.showError('Something is wrong. Check your connectivity!!')
          }
          if(err.response) {
            this.showError(err.response.message)
          }
        } finally {
          this.loading = false
        }

      },
        setVoucherNumberAndDate(withoutDate = false) {
            new Promise(async (resolve, reject) => {
                try {
                    if (!withoutDate) {
                        this.contra.date = new Date().toISOString().split('T')[0]
                    }
                    let query = this.voucherQuery;
                    let voucher = await generateVoucherNumber(query);
                    this.contra.voucher_prefix = voucher.prefix;
                    this.contra.voucher_serial = voucher.serial;
                    resolve();
                } catch (err) {
                    reject(err);
                }
            })

        },
        goToList() {
            this.$router.push({name: 'contra-voucher-list', 
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId
                },
                query: {
                    start: this.start,
                    end: this.end
                }
            
            })
        },
        async resetForm() {
            this.amount = ''
            this.contra = {
                company_id: '',
                date: '',
                note: '',
                mop_references: 'mop',
                voucher_no: '',
                voucher_prefix: '',
                voucher_serial: '',
                voucher_type: 'contra_voucher',
                ledgers: [
                    {
                        account_head_id: null,
                        debit: null,
                        credit: null,
                        note: null,
                        tax_rate: null,
                        taxable_amount: null
                    },
                    {
                        account_head_id: null,
                        debit: null,
                        credit: null,
                        note: null,
                        tax_rate: null,
                        taxable_amount: null
                    }
                ]
            }
            await this.setVoucherNumberAndDate()
        },
        getFormData () {
            let formData = new FormData();
            Object.keys(this.contra).forEach(i => {
                if(i !== 'ledgers') {
                    formData.append(i, this.contra[i])
                }
            })
            let ledgers = this.general.map((ledger) => {
              ledger.contact_profile_id = this.contra.contact_profile_id;
              ledger.business_id = this.contra.business_id;
              return ledger
            })
            formData.append('general_ledgers', JSON.stringify(ledgers))
            return formData;
        },
        async saveContra (redirect = false) {
          //createContra
            this.contra.company_id = this.$route.params.companyId;
            let data = this.getFormData();
            if(redirect) {
                this.loading = true
            } else {
                this.saveNewLoader = true
            }
            try {
                let res = await this.createContra(data)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.showSuccess(res.message)
                    await this.resetForm()
                    if(redirect) this.goToList()
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                    return ;
                }

                if(err.response && err.response.status !== 406) {
                    this.showError(err.response?.data.message)
                }
                if(err.response.data.data && err.response.data.data.show_modal) {
                    this.$refs.voucherValidationModal.openModal(err.response?.data.message)
                }
            } finally {
                this.loading = false
                this.saveNewLoader = false
            }
        }
    },
    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');
        const {decimalFormat} = figureFormatter();

        const {
            fetchAccountHeads,
            createContra,
            loading
        } = handleContra()

        const {
          fetchContactProfiles,
        } = handleContact()

        const {
          fetchBusinessList,
        } = handleCBusinesses()

        return {
            fetchAccountHeads,
            createContra,
            decimalFormat,
            loading,
            showError,
            showSuccess,
            fetchContactProfiles,
            fetchBusinessList,
        }
    },

    async mounted() {
        
        await this.getAccountHeads()
        await this.getContactProfiles()
        await this.getBusinesses()
        await this.setVoucherNumberAndDate()
    }
}
</script>

<style scoped>
    .cancel-btn{
        background-color: #F0F2F5; color: #7D7D7D;
    }
    .cancel-btn:hover{
        background-color: #e9edf5;
    }
    hr{
        margin: 0 -3%;
        width: 106%;
    }
    @media screen and (min-width: 1200px) {
    .btn-close {
        /* margin-left: 200px; */
        /* font-size: 32px; */
        /* margin-top: 5px; */
        width: 30px;
        height: 30px;
        background-color: #E4E6EB;
        border-radius: 50%;
        /* margin-right: 30px; */
    }
    .btn-close:hover {
        width: 30px;
        height: 30px;
        background-color: #D8DADF;
        border-radius: 50%;
    }
    }
    @media screen and (max-width: 720px) {
    .btn-close {
        width: 25px;
        height: 25px;
        background-color: #E4E6EB;
        border-radius: 50%;
        margin-top: -5px;
        margin-right: -5px;
    }
    }
</style>
